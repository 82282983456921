import { z } from "./openapizod";
import * as API from "./mooovex-api-schema";

export const chosenVehicleExtra = z.object({
  path: z.array(API.vehicleproducts.vehicleExtraGroupName.or(API.vehicleproducts.vehicleExtraName)).min(1),
  count: z.number().int().nonnegative().or(z.literal("unknown")),
});

export type ChosenVehicleExtra = z.infer<typeof chosenVehicleExtra>;

export const responseBody = z.object({
  price: z.number().nonnegative(),
  extraPersonPrice: z.number().nonnegative(),
  pricePerPerson: z.number().nonnegative(),
  isBooking: z.boolean(),
});
export type ResponseBody = z.infer<typeof responseBody>;

export const requestBody = z.object({
  route_id: z.string(),
  passengerCount: z.number().int().nonnegative(),
  when: z.object({ date: API.Shared.date, time: API.Shared.time }).or(z.literal("now")),
  discount: z.number().min(-1).optional(),
  vehicle_id: z.string().optional(),
  extras: z.array(API.price.chosenVehicleExtra).optional(),
});

export type RequestBody = z.infer<typeof requestBody>;

export const requestParams = z.object({
  onlinebooking_config_id: z.string(),
});
export type RequestParams = z.infer<typeof requestParams>;
